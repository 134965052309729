<template>
  <div class="box">
    <div class="title">
      <img src="../../assets/image/ding.png" alt="" style="margin-right: 5px" />
      {{ title }}
    </div>
    <div>
      <el-tree
        icon-class="el-icon-circle-plus"
        node-key="chapter_id"
        :default-expanded-keys="[num]"
        :data="datalist"
        highlight-current
        :props="defaultProps"
        @node-click="handleNodeClick"
        :expand-on-click-node="false"
        empty-text="暂无数据"
      ></el-tree>
    </div>
  </div>
</template>

<script>
import URL from "../../api/index";
import eventBus from "@/api/eventBus";
export default {
  data() {
    return {
      title: "一年级语文上册 人教课标版",
      datalist: [],
      defaultProps: {
        children: "son",
        label: "chapter_name",
      },
      num: "",
    };
  },
  components: {},
  mounted() {
    eventBus.$on("bookid", (res) => {
      this.title = res.book_name;
      this.getChapter(res.book_id);
    });
  },
  methods: {
    handleNodeClick(node) {
      eventBus.$emit("chapterid", node.chapter_id);
    },
    getChapter(id) {
      var data = { cptid: id };
      this.$post(URL.getchapter, data).then((res) => {
        this.datalist = res.result.menu;
        this.num = this.datalist[0].chapter_id;
        eventBus.$emit("chapterid", this.num);
      });
    },
  },
};
</script>

<style lang='less' scoped>
.box {
  background-color: #fff;
  border-radius: 16px;
  width: 274px;
  min-height: 475px;
  padding: 20px;
  box-sizing: border-box;
  .title {
    color: #2c84ff;
    padding-bottom: 20px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .el-tree/deep/.el-tree-node__label {
    width: 202px;
    height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.el-tree/deep/.el-tree-node__expand-icon {
  color: #2c84ff !important;
}
.el-tree/deep/.el-tree-node__expand-icon.is-leaf {
  color: transparent !important;
}
</style>