<template>
  <div class="box">
    <div class="clearfix" style="min-height: 550px">
      <div
        class="content"
        v-for="item in list"
        :key="item.course_id"
        @click="getDetails(item)"
      >
        <img
          src="../../assets/image/course.jpg"
          style="border-radius: 16px 16px 0 0; width: 264px"
          alt=""
        />
        <div class="contentbtm">
          <div class="title">{{ item.course_name }}</div>
          <div class="tips">
            <span
              v-if="item.course_free == '0'"
              class="fl"
              style="
                background-color: #ffca45;
                padding: 0 10px;
                color: #fff;
                border-radius: 6px;
              "
              >VIP</span
            >
            <span
              v-else
              class="fl"
              style="
                background-color: #57da67;
                padding: 0 10px;
                color: #fff;
                border-radius: 6px;
              "
              >可试听</span
            >
            <span class="fr" style="color: #87898d"
              >{{ item.course_views }}人观看</span
            >
          </div>
        </div>
      </div>
      <div
        v-if="!list.length"
        style="background-color: #fff; border-radius: 14px; min-height: 476px"
      >
        <el-empty :image-size="220"></el-empty>
      </div>
    </div>
    <!-- 页码 -->
    <div style="text-align: center; padding: 50px 0 80px">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :hide-on-single-page="value"
        :page-size="9"
        layout="prev, pager, next, jumper"
        :total="count"
        prev-text="上一页"
        next-text="下一页"
      >
      </el-pagination>
    </div>
    <!-- 激活弹框 -->
    <el-dialog
      title="会员激活"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
      style="margin-top: 25vh"
    >
      <div style="text-align: center">当前会员未激活，请先激活会员</div>
      <span slot="footer" class="dialog-footer">
        <el-button
          round
          size="small"
          @click="centerDialogVisible = false"
          style="padding: 9px 25px"
          >取 消</el-button
        >
        <el-button round size="small" type="danger" @click="getPay(1)"
          >学习卡激活</el-button
        >
        <!-- hyue jyoeung jie bixudou gainjieruguo jiang -->
        <el-button round size="small" type="primary" @click="getPay(2)"
          >充值中心</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import URL from "../../api/index";
import eventBus from "@/api/eventBus";
export default {
  data() {
    return {
      list: [],
      count: 0,
      currentPage: 1,
      value: true,
      centerDialogVisible: false,
      chapterid: "",
      curPage: 1,
    };
  },
  components: {},
  mounted() {
    eventBus.$on("chapterid", (res) => {
      this.chapterid = res;
      this.getCourseList(res, 1);
    });
  },
  methods: {
    handleCurrentChange(val) {
      this.getCourseList(this.chapterid, val);
    },
    getCourseList(el, page) {
      var data = {
        chapter_id: el,
        page,
        pagenum: 9,
      };
      this.$post(URL.getcourse, data).then((res) => {
        this.list = res.result.data;
        // 总条数和当前页与页码保持一致
        this.count = res.result.count;
        this.currentPage = page;
        if (this.count == 0) {
          this.value = true;
        } else {
          this.value = false;
        }
      });
    },
    getDetails(item) {
      if (item.course_free == "0") {
        // vip
        if (localStorage.getItem("loginInfo")) {
          if (localStorage.getItem("vip") == 1) {
            const {href} = this.$router.resolve({path:`/synchronous/details`,query:{id:`${item.course_id}`}})
            window.open(href,"_blank")
            // this.$router.push(`/synchronous/details?id=${item.course_id}`);
          } else {
            // 不是vip
            this.centerDialogVisible = true;
          }
        } else {
          this.$confirm("此操作需要先进行登录~", "提示", {
            confirmButtonText: "去登录",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$router.push("/login");
            })
            .catch(() => {});
        }
      } else {
        // 可试听
        const {href} = this.$router.resolve({path:`/synchronous/details`,query:{id:`${item.course_id}`}})
            window.open(href,"_blank")
        // this.$router.push(`/synchronous/details?id=${item.course_id}`);
      }
    },
    getPay(id) {
      this.centerDialogVisible = false;
      if (id == 1) {
        this.$router.push("/cardActivation");
      } else {
        this.$router.push("/voucherCenter");
      }
    },
  },
};
</script>

<style lang='less' scoped>
.box {
  width: 900px;
}
.content {
  cursor: pointer;
  float: left;
  margin: 0 15px 30px;
  width: 264px;
  height: 244px;
  background: #ffffff;
  opacity: 1;
  border-radius: 16px;
  .contentbtm {
    padding: 5px 15px;
    .title {
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 22px;
    }
  }

  .tips {
    font-size: 14px;
  }
}
</style>
