<template>
  <div class="box">
    <div>
      年级：
      <span
        :class="{ active: indexgrade == index }"
        v-for="(item, index) in gradelist"
        :key="index"
        @click="getGredeOne(item, index)"
      >
        {{ item.grade_name }}
      </span>
    </div>
    <div>
      科目：
      <span
        :class="{ active: indexsid == item.sid }"
        v-for="(item, index) in sublist"
        :key="index"
        @click="getSubOne(item, index)"
        >{{ item.subject_name }}</span
      >
    </div>
    <div style="padding-bottom: 0">
      课本：
      <div style="margin-top: 5px; padding-left: 46px; padding-bottom: 0">
        <span
          style="
            display: inline-block;
            margin-bottom: 10px;
            width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          "
          :class="{ active: indexAct == index }"
          v-for="(item, index) in booklist"
          :key="index"
          @click="getBookOne(item, index)"
          >{{ item.book_name }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import URL from "../../api/index";
import eventBus from "@/api/eventBus";
export default {
  data() {
    return {
      indexAct: 0,
      indexgrade: 0,
      indexsid: 1,
      grade: 1,
      sid: 1,
      gradelist: [],
      sublist: [],
      booklist: [],
    };
  },
  components: {},
  mounted() {
    this.getGrade();

    if (localStorage.getItem("zjgradeCopy")) {
      this.indexgrade = JSON.parse(localStorage.getItem("zjgradeCopy")) - 1;
      this.getSubject(JSON.parse(localStorage.getItem("zjgradeCopy")));
      this.indexsid = JSON.parse(localStorage.getItem("zjsidCopy"));
      this.getBook(
        JSON.parse(localStorage.getItem("zjgradeCopy")),
        JSON.parse(localStorage.getItem("zjsidCopy"))
      );
      this.indexAct = JSON.parse(localStorage.getItem("zjbook_id")).id - 1;
    } else {
      this.getSubject(1);
      this.getBook(1, 1);
    }
  },
  methods: {
    // 年级
    getGrade() {
      this.$get(URL.getgrade).then((res) => {
        this.gradelist = res.result.grade_list;
      });
    },
    // 科目
    getSubject(res) {
      var data = { gid: res };
      this.$post(URL.getsubject, data).then((res) => {
        this.sublist = res.result.subject_list;
        // 切换年级 科目没有匹配时 默认语文
        if (
          this.sublist.every((item) => {
            if (item.sid != this.sid) {
              return true;
            }
          })
        ) {
          this.sid = 1;
          this.indexsid = 1;
          localStorage.setItem("data_kemu", JSON.stringify('语文'));
          localStorage.setItem("zjsidCopy", JSON.stringify(1));
          this.getBook(this.grade, this.sid);
        }
      });
    },
    // 课本
    getBook(gid, sub) {
      var data = { gid: gid, sid: sub };
      this.$post(URL.getbook, data).then((res) => {
        if (res.result.booklist.length) {
          this.booklist = res.result.booklist;
          eventBus.$emit("bookid", this.booklist[0]);
        }
      });
    },
    // 选择年级
    getGredeOne(res, index) {
      localStorage.setItem("zjgradeCopy", JSON.stringify(res.gid));
      localStorage.setItem("data_nianji", JSON.stringify(res.grade_name));
      // 课本回到第一项
      this.indexAct = 0;
      this.indexgrade = index;
      this.grade = res.gid;
      this.getSubject(res.gid);
      this.getBook(res.gid, this.sid);
    },
    // 选择科目
    getSubOne(sub, index) {
      localStorage.setItem("data_kemu", JSON.stringify(sub.subject_name));
      localStorage.setItem("zjsidCopy", JSON.stringify(sub.sid));
      // 课本回到第一项
      this.indexAct = 0;
      this.indexsid = sub.sid;
      // this.indexsid = index;//不用index 作为选中样式标识
      this.sid = sub.sid;
      this.getBook(this.grade, sub.sid);
    },
    // 选择课本
    getBookOne(res, index) {
      // console.log(res)
      localStorage.setItem("data_bookid", JSON.stringify(res.book_id));
      localStorage.setItem("zjbook_id", JSON.stringify(res));
      this.indexAct = index;
      eventBus.$emit("bookid", res);
    },
  },
};
</script>

<style lang='less' scoped>
.box {
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px 20px 15px;
  margin-top: 30px;
  border-radius: 16px;
  box-sizing: border-box;
  div {
    font-size: 14px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 15px;
    padding-bottom: 15px;
    span {
      margin: 0 20px;
      padding: 4px 14px;
      cursor: pointer;
    }
  }
  div:last-child {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 10px;
  }
}
.active {
  color: #fff;
  border-radius: 14px;
  padding: 4px 14px;
  background-color: #2c84ff;
}
</style>
