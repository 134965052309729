<template>
  <div>
    <!-- <navtop></navtop>
    <menuNav /> -->
    <tabsourse />
    <div class="content clearfix">
      <div class="fl">
        <chapterContents />
      </div>
      <div class="fr" style="margin-left: 10px">
        <courseCard />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../../components/footer.vue";
import courseCard from "@/components/synchronous/courseCard";
import chapterContents from "@/components/synchronous/chapterContents";
import tabsourse from "@/components/synchronous/tabsourse";
import navtop from "@/components/navtop.vue";
import menuNav from "@/components/menuNav.vue";
export default {
  name: "courseList",
  components: {
    navtop,
    menuNav,
    tabsourse,
    chapterContents,
    courseCard,
    Footer
  },
  data() {
    return {};
  },

  mounted() {},
  methods: {},
};
</script>

<style lang='less' scoped>
.content {
  width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
}
</style>
